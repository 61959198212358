;(function(window, $) {
  $(function () {
    var popovers = $('[data-toggle="popover"]');

    if(popovers.length > 0) {
      popovers.popover({
        html: true
      });
    }

    $(window).on('show.bs.modal', function() {
      popovers.each(function() {
        $(this).popover('hide');
      });
    });
  });
})(window, jQuery);
