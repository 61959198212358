;(function(window, $) {
  $(function () {
    var systemAlert = $('.alert-system').first();

    if( systemAlert.length > 0 ) {
      systemAlert.find('.close').click(function(e) {
        e.preventDefault();

        var theURL = $(this).attr('href');

        $.get(theURL).done(function() {
          systemAlert.alert('close');
        });
      });
    }
  });
})(window, jQuery);
